import frontendEnv from "../../../Env/frontend.mjs"

export default {
    isValidDomain:frontendEnv.isValidDomain?frontendEnv.isValidDomain:(url)=>{
        try {
            // Create a URL object
            const urlObj = new URL(url);
            
            // Extract the hostname from the URL
            const hostname = urlObj.hostname;
        
            // Check if the hostname ends with the given domain
            return hostname.endsWith(`.nativedigital.ch`);
          } catch (e) {
            // If an invalid URL is provided, return false
            return false;
          }
    }
}